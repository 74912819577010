import React from 'react';
import Header from './landing/Header';
import Main from './landing/MainBody';
import Footer from './landing/Footer';

function App() {
  // add header and main
  return (
    <div className="App">
      <Header />
      <Main />
      <Footer />
    </div>
  );
}

export default App;
