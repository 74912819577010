
  import React from "react";
  
  
  // Define a functional component named Main

  const Main = () => {
    // Return a JSX element that renders the main content of the website
    return (
      <div className="main">
        <div className="hero">
          <h2>Welcome to Geek Advice!</h2>
          <p>
            This is a basic template website made with React.
          </p>
          <button>Learn More</button>
        </div>
  
        <div className="features">
          <div
            className="feature"
            style={{ display: "flex", flexDirection: "row" }}
          >
          </div>
        </div>
      </div>
    );
  };
  
  export default Main;