import {
    faHome,
    faInfoCircle,
    faCog,
    faEnvelope,
  } from "@fortawesome/free-solid-svg-icons";
  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
  import React from "react";
  
  // Define a functional component named NavItem
  const NavItem = ({ icon, link }) => {
    // Return a JSX element that renders the icon and link props as a list item with an anchor tag
    return (
      <li className="nav-item">
        <a href={link}>
          <FontAwesomeIcon icon={icon} />
        </a>
      </li>
    );
  };
  
  // Define an array of objects that represent the navigation items
  const navItems = [
    { icon: faHome, link: "#" },
    { icon: faInfoCircle, link: "#" },
    { icon: faCog, link: "#" },
    { icon: faEnvelope, link: "#" },
  ];
  
  // Define a functional component named Header
  const Header = () => {
    // Return a JSX element that renders the header of the website
    return (
      <div className="header">
        <h1>Geek Advice</h1>
        <ul className="nav-list">
          {navItems.map((item, index) => (
            <NavItem key={index} icon={item.icon} link={item.link} />
          ))}
        </ul>
      </div>
    );
  };
  
  export default Header;